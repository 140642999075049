<template>
  <BaseAlinea class="about-me" useBg>
    <template #header> Over Mij </template>
    <p>
      Ik ben Marianne, maatschappelijk betrokken, optimistisch, en enthousiast.
      In het hele leven stel je jezelf vragen, heel lang was het voor mij
      belangrijk wat anderen van mij vonden. Als aanstaande moeder stelde ik
      mezelf de vraag of ik een goede moeder zou zijn, maar ook vragen in
      relatie tot mijn partner, familie en vrienden hielden mij bezig. Naarmate
      de jaren verstreken vroeg ik mezelf af welke stappen ik kon zetten in mijn
      ontwikkeling. Daar kwam de wens die ik al langer koesterde; coach zijn.
    </p>

    <div class="about-me__img-container">
      <img
        class="about-me__img"
        src="@/assets/images/marianne@2x.png"
        alt="Foto van mijzelf"
      />
    </div>
  </BaseAlinea>
</template>

<script lang="ts">
import BaseAlinea from "@/components/base/BaseAlinea.vue";
import { defineComponent } from "vue";

export default defineComponent({ components: { BaseAlinea } });
</script>

<style scoped lang="scss">
@import "@/global";
.about-me {
  padding-bottom: 28px;
  &__img-container {
    text-align: center;
    margin-top: 28px;
  }

  &__img {
    width: 103px;
    height: 103px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
  }
}
</style>
