<template>
  <BaseAlinea class="intro" noHeader useBg>
    <div class="intro__top-container">
      <BaseButton @click="handleReadMore()">Lees meer</BaseButton>
      <img
        class="intro__jump-img"
        src="@/assets/images/marianne-jump@2x.png"
        alt="marianne aan het springen"
      />
    </div>

    <h1 class="intro__header">
      Samen op zoek naar jouw eigen wijsheid!
    </h1>
    <p id="lees-meer">
      Loop je vast op het gebied van werk, op relationeel gebied, (e)migratie,
      je familiegeschiedenis, corona?
    </p>

    <p>Voel je je kwetsbaar door een ingrijpende gebeurtenis?</p>

    <p>Sta je op de drempel van iets nieuws?</p>

    <p>
      Worden oude trauma’s geactiveerd door een nieuwe fase of een verlies in je
      leven?
    </p>

    <p>
      Ben je op zoek naar een coach die je ondersteunt en die samen met jou,
      zonder oordeel op zoek gaat naar jouw eigen wijsheid?
    </p>

    <div class="intro__flex">
      <div class="intro__img-container">
        <img
          class="intro__office-img"
          src="@/assets/images/office@2x.jpg"
          alt="foto van mijn kantoor"
        />
      </div>

      <p class="intro__bottom-text">
        Je EigenWijzer biedt Coaching in een veilige ruimte. Een ruimte waar
        plaats is voor kwetsbaarheid, creativiteit, groei en ontwikkeling. Waar
        patronen doorbroken kunnen worden. Waar afscheid genomen kan worden van
        het oude en het nieuwe verwelkomd mag worden. Waar we op zoek gaan naar
        beschermjassen die je helpen verdriet en verlies te dragen.
      </p>
    </div>

    <div class="intro__video_container">
      <video
        class="intro__video"
        width="320"
        height="240"
        ref="videoPlayer"
        controls
      >
        <source src="@/assets/videos/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="intro__contact-container">
      <BaseButton @click="onClick()">Neem contact op</BaseButton>
    </div>
  </BaseAlinea>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BaseButton,
    BaseAlinea
  },
  setup() {
    const router = useRouter();

    const onClick = () => {
      router.push({ name: "Contact" });
    };

    const handleReadMore = () => {
      router.push({ name: "Home", hash: "#lees-meer" });
    };

    return { onClick, handleReadMore };
  }
});
</script>

<style scoped lang="scss">
@import "@/global";

.intro {
  padding-bottom: 28px;

  margin-top: 0 !important;

  &__header {
    transform: translatey(-70px);
    color: $primary2;
    font-size: 20px;
    margin-bottom: 0;
    width: 50%;
  }

  &__top-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    transform: translateY(-50%);
    margin-bottom: -120px;
  }

  &__video_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__video {
    width: 320px;
    margin: 0 auto;
  }

  &__jump-img {
    width: 134px;
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__office-img {
    width: 283px;

    border-radius: 50%;
    height: 283px;
    margin: 15px 0;
    object-fit: cover;
  }

  &__contact-container {
    margin-top: 22px;
    text-align: center;
  }
}

@include laptop {
  .intro {
    &__flex {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__office-img {
      $size: 175px;
      width: $size;
      margin: 0;

      height: $size;
    }

    &__bottom-text {
      margin-left: 32px;
    }
  }
}

@include tablet {
  .intro {
    &__header {
      width: 70%;
    }
  }
}

@include laptop {
  .intro {
    &__header {
      width: 80%;
    }
  }
}
</style>
