<template>
  <div class="hero">
    <div class="hero__sidescroll">
      <div class="hero__half-circle"></div>
    </div>

    <div class="hero__title-container">
      <h1 class="hero__title wrapper" :class="`hero__title--${titleAlignment}`">
        <slot />
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    titleAlignment: {
      type: String as PropType<"right" | "left" | "center">,
      default: "center"
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/global";

.hero {
  z-index: 0;
  margin-top: 100px;
  height: 400px;
  position: relative;
  background: #d4d1ce;

  &__sidescroll {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__half-circle {
    height: 120%;
    width: 120%;
    background: white;
    border-radius: 50%;
    transform: translate(-10%, -75%);
  }

  &__title-container {
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(275px + -5%);
  }
}

@include laptop {
  .hero {
    &__path1 {
      display: none;
    }
  }
}
</style>
