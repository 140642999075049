import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterOptions,
  RouterScrollBehavior
} from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/methodieken",
    name: "Methods",
    component: () =>
      import(/* webpackChunkName: "methods" */ "../views/Methods.vue")
  },
  {
    path: "/tarieven",
    name: "Prices",
    component: () =>
      import(/* webpackChunkName: "prices" */ "../views/Prices.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    strict: true,
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
  },
  {
    path: "/recencies",
    name: "Reviews",
    component: () =>
      import(/* webpackChunkName: "review" */ "../views/Reviews.vue")
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash)
      return {
        el: to.hash,
        behavior: "smooth",
        top: 130
      };
  }
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
