<template>
  <div class="alinea" v-bind="$attrs">
    <div class="alinea__content wrapper">
      <h1
        v-if="!noHeader"
        :class="smallHeading ? 'alinea__header--small' : 'alinea__header'"
      >
        <slot name="header" />
      </h1>
      <slot />
    </div>

    <div class="alinea__img-container" v-if="useBg">
      <img
        class="alinea__bg"
        src="@/assets/images/pattern@2x.png"
        alt="achtergrond patroon"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    useBg: {
      default: false,
      type: Boolean
    },

    noHeader: {
      required: false,
      type: Boolean,
      default: false
    },
    smallHeading: {
      required: false,
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/global";
.alinea {
  margin-top: 42px;
  position: relative;
  // padding-bottom: 28px;

  &__img-container {
    z-index: -1;
    position: absolute;
    width: 100%;
    left: 50%;
    bottom: -5px;
    width: 100%;
    height: auto;
    transform: translateX(-50%);
    text-align: center;
  }

  &__bg {
    height: 100%;
    // width: 100%;
  }

  &__header {
    color: $primary1;
  }

  &__header--small {
    color: $primary2;
    font-size: 20px;
    margin-bottom: 0;
  }
}
</style>
