<template>
  <div class="quote-container">
    <div class="wrapper">
      <h1 class="quote-container__quote">“{{ quote }}”</h1>
      <p class="quote-container__author">{{ author }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

const props = {
  quote: {
    type: String,
    required: true,
  },
  author: {
    type: String,
    required: true,
  },
};

export default defineComponent({
  props: props,
});
</script>

<style lang="scss" scoped>
@import "@/global";

.quote-container {
  background: $accent2;
  text-align: center;
  padding: 15px 0;
  width: 100%;

  &__quote {
    font-size: 18px;
    color: white;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
}
</style>
