<template>
  <BaseAlinea class="vision">
    <template #header>Visie</template>
    <p>
      Mijn visie is het gunnen aan eenieder om gecoacht te worden daar waar je
      vastloopt. Iedereen is welkom! Het gunnen is een rode draad in mijn leven.
      Zelf heb ik ervaren hoe het is om uitgesloten te worden. Door deze
      ervaring heb ik een warmte ontwikkeld om mensen in te sluiten. Mijn missie
      is het verschil maken als coach, door samen met jou op zoek te gaan naar
      jouw eigen wijsheid. Ik wil jou je vrij, geaccepteerd, gezien en gehoord
      laten voelen, jou in je eigen kracht zetten. Ik gun jou een coachtraject
      met mij. Ben je nieuwsgierig geworden? Neem contact op. Ik sta je graag te
      woord en wil samen met jou kijken naar wat ik voor je kan betekenen.
    </p>
    <div class="vision__btn-container">
      <BaseButton @click="onClick()">Neem contact op</BaseButton>
    </div>
  </BaseAlinea>
</template>

<script lang="ts">
import BaseButton from "@/components/base/BaseButton.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";

import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BaseButton,
    BaseAlinea
  },
  setup() {
    const router = useRouter();

    const onClick = () => {
      router.push({ name: "Contact" });
    };

    return { onClick };
  }
});
</script>

<style scoped lang="scss">
@import "@/global";
.vision {
  &__btn-container {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
