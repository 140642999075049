<template>
  <div class="hero__img-container">
    <img :src="imgSrc" :alt="imgAlt" class="hero__img" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    imgSrc: {
      required: true,
    },
    imgAlt: {
      required: true,
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/global";

.hero {
  &__img-container {
    position: absolute;
    top: 10px;
    right: -10%;
    z-index: 2;
  }

  &__img {
    $size: 325px;
    height: $size;
    width: $size;
    border-radius: 50%;
  }
}

@include tablet {
  .hero {
    &__img-container {
      right: 20%;
    }
  }
}

@include laptop {
  .hero {
    &__img-container {
      right: calc((100% - 768px) / 2);
    }
  }
}

@include desktop {
  .hero {
    &__img-container {
      right: calc((100% - 1024px) / 2);
    }
  }
}
</style>
