
import BaseButton from "@/components/base/BaseButton.vue";
import BaseHero from "@/components/base/BaseHero.vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { BaseHero, BaseButton },
  setup() {
    const router = useRouter();

    const handleClick = () => {
      router.push({ name: "Home" });
    };

    return { handleClick };
  }
});
