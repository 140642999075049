
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const menuIsOpen = ref(false);
    const isCompact = ref(false);

    const handleScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        isCompact.value = true;
      } else {
        isCompact.value = false;
      }
    };

    window.addEventListener("scroll", handleScroll);

    const routes = [
      {
        name: "Home",
        displayName: "Home"
      },
      {
        name: "Methods",
        displayName: "Methodieken"
      },
      {
        name: "Prices",
        displayName: "Tarieven"
      },
      {
        name: "Reviews",
        displayName: "Recensies"
      },
      {
        name: "Contact",
        displayName: "Contact"
      }
    ];

    const toggleMenu = () => {
      menuIsOpen.value = !menuIsOpen.value;
    };

    return { menuIsOpen, toggleMenu, routes, isCompact };
  }
});
