<template>
  <div class="not-found">
    <BaseHero>
      Pagina niet gevonden

      <BaseButton @click="handleClick()" style="display:block; margin-top: 1em"
        >Terug naar home
      </BaseButton>
    </BaseHero>
  </div>
</template>

<script lang="ts">
import BaseButton from "@/components/base/BaseButton.vue";
import BaseHero from "@/components/base/BaseHero.vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { BaseHero, BaseButton },
  setup() {
    const router = useRouter();

    const handleClick = () => {
      router.push({ name: "Home" });
    };

    return { handleClick };
  }
});
</script>

<style lang="scss" scoped></style>
