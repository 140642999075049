
import { defineComponent, PropType } from "vue";

const props = {
  variant: {
    required: false,
    type: String as PropType<"primary" | "secondary">,
    default: "primary"
  }
};

export default defineComponent({
  props: props
});
