<template>
  <div class="home">
    <BaseHeroImg
      imgAlt="Lang met zee"
      :imgSrc="require('@/assets/images/home-hero.png')"
    />
    <BaseHero imgAlt="zee met pad" titleAlignment="left">
      <span class="home__subheader">Hoi ik ben Marianne,</span>
      <br />
      <span class="home__header">Je Coach</span>
    </BaseHero>

    <Introduction />

    <BaseQuote
      quote="Be patient. You never know when the change is going to happen"
      author="Steve de Shazer"
    />

    <AboutMe />

    <BaseQuote
      quote="Achter alle leed glinstert iets dat u van schoonheid zuchten doet"
      author="Felix Timmermans"
    />

    <Vision />
  </div>
</template>

<script lang="ts">
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import Introduction from "@/components/home/Introduction.vue";
import AboutMe from "@/components/home/AboutMe.vue";
import Vision from "@/components/home/Vision.vue";
import BaseQuote from "@/components/base/BaseQuote.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BaseHero,
    BaseHeroImg,
    Introduction,
    BaseQuote,
    AboutMe,
    Vision
  },
  setup() {
    const click = () => {
      console.log("click");
    };
    return { click };
  }
});
</script>

<style scoped lang="scss">
.home {
  position: relative;
  overflow-x: hidden;

  &__subheader {
    font-size: 15px;
  }

  &__header {
    font-size: 35px;
  }
}
</style>
