
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    useBg: {
      default: false,
      type: Boolean
    },

    noHeader: {
      required: false,
      type: Boolean,
      default: false
    },
    smallHeading: {
      required: false,
      type: Boolean,
      default: false
    }
  }
});
