
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    imgSrc: {
      required: true,
    },
    imgAlt: {
      required: true,
      type: String,
    },
  },
});
