<template>
  <footer class="footer">
    <div class="footer__circle">
      <img src="@/assets/paths/pink-circle.svg" alt="" />
    </div>

    <div class="footer__grid wrapper">
      <div class="footer__logo">
        <img src="@/assets/logo/logo-no-line.svg" alt="Je EigenWijzer logo" />
      </div>
      <div class="footer__quick-links">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/methodieken">Methodieken</router-link></li>
          <li><router-link to="/tarieven">Tarieven</router-link></li>
          <li><router-link to="/recencies">Recencies</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
      <div class="footer__socials">
        <ul>
          <li>
            <a href="mailto:info@jeeigenwijzer.nl">info@jeeigenwijzer.nl</a>
          </li>
          <li>
            <a href="https://www.jeeigenwijzer.nl" target="_BLANK">
              www.jeeigenwijzer.nl
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/jeeigenwijzercoaching/"
              target="_BLANK"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/je-eigenwijzer-coaching/j"
              target="_BLANK"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__terms">
        <a
          href="./Algemene voorwaarden.pdf"
          target="_BLANK"
          type="application/pdf"
          >Algemene voorwaarden
        </a>
      </div>
      <div class="footer__copy">
        <p>Developed by Koen de Bruijn &copy;2021</p>
      </div>
      <div class="footer__connected">
        <p class="footer__connected_text">
          Aangesloten bij LVSC Beroepsvereniging en beroepsregister voor Coach,
          Supervisor en Organisatiebegeleider.
        </p>
        <div class="footer__connected_logos">
          <img
            class="footer__connected_lvsc"
            src="@/assets/images/lvsc.png"
            alt="LVSC"
          />
          <a
            href="https://www.coachfinder.nl/coach/marianne-van-wolde-de-rooij/"
            target="_BLANK"
          >
            <img
              class="footer__connected_coachfinder"
              src="@/assets/images/keurmerk-coachfinder-200x200.png"
              alt="LVSC"
            />
          </a>
        </div>
      </div>

      <div class="footer__white-circle"></div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
@import "@/global";

a,
p {
  font-size: 13px;
}

.footer {
  margin-top: 100px;
  position: relative;
  overflow: hidden;

  &__circle {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 200%;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  &__white-circle {
    display: none;
  }

  &__grid {
    display: grid;
    grid-template-areas:
      "logo logo"
      "quick-links socials"
      "quick-links terms"
      "connected connected"
      "copy copy";
    list-style: none;
    // justify-items: ;
    margin: 70px auto;
  }

  &__connected {
    grid-area: connected;
    margin: 1em 0;
  }

  &__connected_lvsc {
    margin-top: 1em;
    height: 50px;
  }

  &__connected_coachfinder {
    margin-top: 1em;
    height: 75px;
    width: 75px;
  }

  &__connected_logos {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

  &__logo {
    grid-area: logo;
    justify-self: center;
    margin-bottom: 1em;
  }

  &__quick-links {
    grid-area: quick-links;
    // margin-right: 0.5em;
  }
  &__socials {
    grid-area: socials;
  }

  &__terms {
    align-self: flex-end;
    grid-area: terms;
    // margin: 0.5em 0;
  }
  &__copy {
    text-align: center;
    grid-area: copy;
    font-style: italic !important;
    p {
      font-size: 10px;
    }
  }
}

@include laptop {
  .footer {
    background: #d4d1ce;
    position: relative;
    z-index: 0;

    &__circle {
      display: none;
    }

    &__grid {
      margin: 30px auto 70px auto;
      justify-content: space-between;
      z-index: 0;
      position: relative;
      grid-template-columns: 0.8fr 1fr 2fr;
      grid-template-areas:
        "logo logo logo"
        "quick-links socials connected"
        "quick-links terms  copy";
    }

    &__logo {
      margin-bottom: 2em;
    }

    &__copy {
      text-align: left;
      align-self: flex-end;
    }

    &__quick-links {
      margin-right: 0;
    }

    &__quick-links {
      justify-content: flex-end;
    }

    &__connected {
      display: flex;
      margin: 0;
      justify-content: flex-end;
    }

    &__connected_text {
      margin-right: 1em;
    }

    &__connected_logos {
      display: flex;
      flex-direction: column;
    }

    &__connected_logo {
      // align-self: flex-end;
      margin-bottom: 4px;
      box-sizing: unset;
    }

    &__white-circle {
      display: block;
      background: white;
      position: absolute;
      width: 1000px;
      border-radius: 50%;
      height: 1000px;
      top: -200px;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@include desktop {
  .footer {
    &__white-circle {
      width: 1300px;
      height: 1300px;
      top: -300px;
    }

    &__circle-small {
      transform: translateX(100px);
      img {
        width: 1300px;
      }
    }
  }
}
</style>
