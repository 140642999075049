
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    titleAlignment: {
      type: String as PropType<"right" | "left" | "center">,
      default: "center"
    }
  }
});
