
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import Introduction from "@/components/home/Introduction.vue";
import AboutMe from "@/components/home/AboutMe.vue";
import Vision from "@/components/home/Vision.vue";
import BaseQuote from "@/components/base/BaseQuote.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BaseHero,
    BaseHeroImg,
    Introduction,
    BaseQuote,
    AboutMe,
    Vision
  },
  setup() {
    const click = () => {
      console.log("click");
    };
    return { click };
  }
});
