
import BaseButton from "@/components/base/BaseButton.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";

import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BaseButton,
    BaseAlinea
  },
  setup() {
    const router = useRouter();

    const onClick = () => {
      router.push({ name: "Contact" });
    };

    return { onClick };
  }
});
