<template>
  <button v-bind="$attrs" :class="`button button--${variant}`">
    <p><slot /></p>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

const props = {
  variant: {
    required: false,
    type: String as PropType<"primary" | "secondary">,
    default: "primary"
  }
};

export default defineComponent({
  props: props
});
</script>

<style lang="scss" scoped>
@import "@/global";
.button {
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 0 10px;
  min-width: 156px;
  height: 40px;
  transition: 100ms background ease-in-out;
  margin-bottom: 10px;

  &--primary {
    background: $primary1;

    &:hover {
      transition: 100ms background ease-in-out;
      background: darken($primary1, 10);
    }

    & p {
      color: white;
    }
  }

  &--secondary {
    background: white;

    & p {
      color: $primary2;
    }
  }
}
</style>
