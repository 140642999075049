<template>
  <Navigation />
  <router-view class="page" />
  <Footer />
</template>

<script>
import Navigation from "./components/layout/Navigation.vue";
import Footer from "./components/layout/Footer.vue";

export default {
  components: {
    Footer,
    Navigation
  }
};
</script>

<style lang="scss">
@import "@/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("//hello.myfonts.net/count/3cca1b");
@font-face {
  font-family: "LoveYaLikeASister";
  src: url("./assets/fonts/LoveYaLikeASister/font.woff2") format("woff2"),
    url("./assets/fonts/LoveYaLikeASister/font.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
}

#app {
  position: relative;
  overflow-x: hidden;
}

h1 {
  font-family: LoveYaLikeASister;
  color: $primary2;
  font-size: 24px;
  margin-bottom: 10px;
}

p,
a,
label,
input,
address,
textarea,
textarea::placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: $primary2;
  line-height: 22px;
  font-style: normal;
  text-decoration: none;
}

.page {
  margin-top: 210px;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
}

ul {
  list-style: none;
}

@include tablet {
  .wrapper {
    width: 60%;
  }
}

@include laptop {
  .wrapper {
    width: 768px;
  }
}

@include desktop {
  .wrapper {
    width: 1024px;
  }
}
</style>
