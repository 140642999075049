<template>
  <nav class="nav" :class="{ 'nav--active': menuIsOpen }">
    <ul class="nav__list">
      <li class="nav__item" @click="toggleMenu">
        <p class="nav__link">X</p>
      </li>
      <li
        class="nav__item"
        v-for="route in routes"
        :key="route.name"
        @click="toggleMenu"
      >
        <router-link class="nav__link" :to="{ name: route.name }">
          {{ route.displayName }}
        </router-link>
      </li>
    </ul>
  </nav>

  <header class="header" :class="{ 'header--compact': isCompact }">
    <div class="header__wrapper wrapper">
      <div class="header__logo">
        <img src="@/assets/logo/logo.svg" alt="je EigenWijzer logo" />
      </div>

      <nav class="big-nav">
        <ul class="big-nav__list">
          <li class="big-nav__item" v-for="route in routes" :key="route.name">
            <router-link class="big-nav__link" :to="{ name: route.name }">
              {{ route.displayName }}
            </router-link>
          </li>
        </ul>
      </nav>

      <div
        class="header__hamburger"
        :class="{ 'header__hamburger--compact': isCompact }"
      >
        <img src="@/assets/icons/hamburger.svg" @click="toggleMenu" />
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const menuIsOpen = ref(false);
    const isCompact = ref(false);

    const handleScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        isCompact.value = true;
      } else {
        isCompact.value = false;
      }
    };

    window.addEventListener("scroll", handleScroll);

    const routes = [
      {
        name: "Home",
        displayName: "Home"
      },
      {
        name: "Methods",
        displayName: "Methodieken"
      },
      {
        name: "Prices",
        displayName: "Tarieven"
      },
      {
        name: "Reviews",
        displayName: "Recensies"
      },
      {
        name: "Contact",
        displayName: "Contact"
      }
    ];

    const toggleMenu = () => {
      menuIsOpen.value = !menuIsOpen.value;
    };

    return { menuIsOpen, toggleMenu, routes, isCompact };
  }
});
</script>

<style scoped lang="scss">
@import "@/global";

.header {
  overflow: hidden;
  height: 200px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  z-index: 99;
  background: white;
  padding: 15px 0;

  transition: all 0.25s ease-in-out;

  &__wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .big-nav {
    display: none;
  }

  &__logo {
    height: 80%;
    box-sizing: unset;

    img {
      height: 100%;
    }
  }

  &__hamburger {
    height: 20px;
    align-self: flex-start;
    transition: all 0.25s ease-in-out;

    img {
      height: 100%;
    }

    &--compact {
      // margin: 0 auto;
      margin: 37.5px 0;
    }
  }

  &--compact {
    height: 125px;
    box-shadow: 0px 3px 6px #00000029;
  }
}

.nav {
  z-index: 900;
  $size: 400px;
  width: $size;
  height: $size;
  position: fixed;
  top: 0;
  right: 0;
  background: $primary2;
  border-radius: 50%;
  display: flex;
  clip-path: circle(0%);
  transform: translate(50%, -15%);

  transition: clip-path 0.5s ease-in-out;

  &--active {
    clip-path: circle(100%);
  }

  &__list {
    z-index: 999;
    align-self: center;
    margin-left: 3em;
    list-style: none;
  }

  &__item {
    text-align: right;
    margin-bottom: 22px;
  }

  &__link,
  &__close {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-family: LoveYaLikeASister;
    transition: 0.1s color ease-in-out;
    cursor: pointer;

    &.router-link-exact-active {
      color: $primary1;
    }

    &:hover {
      color: $primary1;
    }
  }
}

@include laptop {
  .nav {
    display: none;
  }

  .header {
    &__hamburger {
      display: none;
    }

    .big-nav {
      display: block;

      &__list {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
      }

      &__link {
        font-family: LoveYaLikeASister;
        transition: 0.1s color ease-in-out;
        cursor: pointer;

        &.router-link-exact-active {
          color: $primary1;
        }

        &:hover {
          color: $primary1;
        }
      }
    }
  }
}
</style>
